import axios from "axios";

const url =
  process.env.NODE_ENV === "development"
    ? `http://127.0.0.1/api/kuisioner/`
    : `https://rsuppersahabatan.co.id/apis/kuisioner/`;

let token = process.env.REACT_APP_API;

const headers = { Authorization: `Persahabatan ${token}` };

export default axios.create({
  baseURL: url,
  timeout: 50000,
  headers: headers
});
