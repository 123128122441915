import React, { useState } from 'react'
import styled from 'styled-components'
import { Button } from './Button'

import { string } from "yup"
import { nikParser } from 'nik-parser'

import primaAPI from '../api/prima'


const Title = styled.h1`
    margin-top: 4em;
    font-size: 48px;
`;

const SubTitle = styled.h2`
    font-size: 15px;
    margin-bottom: 2em; 
`;

const Points = styled.p`
    font-size: 24px;
    margin-bottom: 3em;
`;

const Input = styled.input`
    font-size: 18px;
    padding: 10px;
    margin: 10px;
    background: papayawhip;
    border: none;
    border-radius: 3px;

    ::placeholder {
        color: palevioletred;
    }
`;

function Hasil({nilai,data}) {

    const refreshPage = () => window.location.reload();

    const [loading, setLoading] = useState(false);
    const [isError, setIsError] = useState(false);
    const [noRm, setNoRm] = useState('');
    const [valid, setValid] = useState(false);
    const [hasil, setHasil] = useState(data);
    const [dataSubmit, setDataSubmit] = useState(null);

    const simpanData = async (e) => {

        e.preventDefault();

        const dataSubmit = {
            norm: noRm,
            hasil: JSON.stringify(hasil),
            nilai: nilai,
        }

        if (!dataSubmit.norm) {
            alert('Diklik Simpan, Nomor Rekam Medis (RM) Tidak Boleh Kosong!')
            setIsError(false)
        }

        try {
            const response = await primaAPI.post('/simpan', dataSubmit, {
                mode: 'no-cors',
                method: "POST",
                body: setDataSubmit(dataSubmit)
            });

            alert("Submitting complete: "+response.statusText);
            setIsError(true)
            setLoading(false)
              
        } catch (e) {
            setIsError(false)
            setLoading(true)
        }
    }

    // source: https://codesandbox.io/embed/0358ryl6ww?fontsize=14&hidenavigation=1&theme=dark
    const handleNorm = e => {
        const value = e.target.value;
        setNoRm(value);
        setValid(
            string().test('len', 'Nomor KTP tidak valid bro...', value => (nikParser(value).isValid() === true)).isValidSync(value)
        )
    }

    const Sekor = (nilai) => {
        if(nilai >= 0 && nilai <= 4) {
            return 'Ringan';
        }

        if(nilai >= 5 && nilai <= 7) {
            return 'Sedang-Berat';
        }

        if(nilai >= 8 && nilai <= 10) {
            return 'Sangat Berat';
        }
    }

    if (loading) return 'loading...';
    return (
        <div>
            <Title>~ Selesai ~</Title>
            <Points>Hasil: {Sekor(nilai)} </Points>
            <SubTitle>Harap Input No KTP/ NIK Jika Hendak Disimpan.</SubTitle>
            <Input type="text" name="norm" value={noRm} onChange={handleNorm} placeholder="No KTP/ NIK" required='required' disabled={isError} />
            <h1>{valid ? isError ? "Ok." : "NIK Valid" : "Tidak Valid"}</h1>
            <Input type="hidden" name="hasil" value={hasil} onChange={e => setHasil(e.target.value)} />

            {console.log({...dataSubmit})}

            <Button onClick={(e) => simpanData(e)} simpan={valid ? isError? true : false : true} disabled={isError}> {valid ? isError? 'Selesai' : 'Simpan' : 'Selesai'}</Button>
            <Button onClick={refreshPage} simpan={false}>Ulangi</Button>
        </div>
    )
}

export default Hasil
