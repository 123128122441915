import React, { useState } from 'react';
import './App.css';

// Page
import Mulai from './Components/Mulai'
import Kuis from './Components/Kuis'

function App() {

  const [mulai, setMulai] = useState(false);

  return (
    <div className="Kuis">
      { mulai ? <Kuis /> : <Mulai props={setMulai} />}
    </div>
  );
}

export default App;
