import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { kuis as kuisData } from '../Helpers/Data'
import Hasil from './Hasil'

const KuisFrame = styled.div`
    text-align: center;
    font-size: clamp(20px, 2.5vw, 24px);
    margin-top: 10vh;
`;

const SoalFrame = styled.div`
    width: 70%;
    margin: 0 auto;
`;

const Options = styled.div`
    display: flex;
    flex-direction: column;
    width: 70%;
    margin: 2em auto;

    @media screen and (min-width: 1180px){
        width: 50%;
    }
`;

const Option = styled.button`
    display: block;
    border: 1px solid #616A94;
    border-radius: 15px;
    padding: 15px 30px;
    text-decoration: none;
    color: #616A94;
    background-color: #161A31;
    transition: 0.3s;
    font-size: 1em;
    outline: none;
    user-select: none;
    margin-top: 1em;
    cursor: pointer;

    @media screen and (min-width: 1180px) {
        &:hover {
            color: white;
            background-color: #616A94
        }
    }
`;

function Kuis() {

    const [kuis, setKuis] = useState(kuisData);
    const [nomor, setNomor] = useState(0);
    const [nilai, setNilai] = useState(0);
    const [data, setData] = useState([]);

    const pilihJawaban = (s,e) => {
        let soalUser = s.soal;
        let pilihanDariSoal = s.pilihan; 
        let jawabanUser = e.nilai;

        if(jawabanUser) setNilai(nilai+jawabanUser)
        
        // Pecah dan Bandingkan
        const pilihYa = pilihanDariSoal.map( item => e.label === item.label ? {
                ...item,
                selected: true,
                soal: soalUser
            } : {
                ...item,
                selected: false,
                soal: soalUser
            }
                // console.log('Pilihan user:',e.label, 'Dari Sistem:', item.label, 'bandingkan: ', e.label === item.label)
        )
        
        // tampilkan hanya yang true
        const filterYa = pilihYa.filter(i => i.selected === true)
        
        setData(
            [...data, ...filterYa]
        )
        setNomor(nomor + 1)
    }

    useEffect(() => {

        setKuis(kuis.map(item => (
            {
                soal: item.soal,
                pilihan: [...item.pilihan]
            }
        )))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        // console.log(data),
        <KuisFrame>
            {kuis[nomor] &&
            <>
                <SoalFrame dangerouslySetInnerHTML={{ __html: kuis[nomor].soal}}></SoalFrame>
                <Options>
                    {kuis[nomor].pilihan.map((item,index) => (
                        <Option key={index} dangerouslySetInnerHTML={{ __html: item.label }} onClick={() => pilihJawaban(kuis[nomor],item)}></Option>
                    ))}
                </Options>
            </>
            }

            {
                nomor === kuis.length && <Hasil nilai={nilai} data={data} />
            }
        </KuisFrame>
    )
}

export default Kuis
