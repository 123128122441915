export const kuis = [
    {
        id: 1,
        soal: 'Berapa Banyak Rokok yang anda hisap dalam 1 hari?',
        pilihan: [
            {
                label: '1-10',
                nilai: 0,
            },
            {
                label: '11-20',
                nilai: 1,
            },
            {
                label: '21-30',
                nilai: 2,
            },
            {
                label: '31 atau lebih',
                nilai: 3
            }
        ]
    },
    {
        id: 2,
        soal: 'Seberapa cepat anda menyalakan rokok pertama anda setelah anda terjaga?',
        pilihan: [
            {
                label: '< 5 Menit',
                nilai: 3,
            },
            {
                label: '6 - 30 Menit',
                nilai: 2,
            },
            {
                label: '31 - 60 Menit',
                nilai: 1,
            },
            {
                label: '> 60 Menit',
                nilai: 0
            }
        ]
    },
    {
        id: 3,
        soal: 'Jika anda berminat berhenti merokok, manakah yang anda rasakan paling sulit dihentikan?',
        pilihan: [
            {
                label: 'Menghisap batang rokok pertama pada pagi hari?',
                nilai: 1,
            },
            {
                label: 'Lainnya',
                nilai: 2,
            }
        ]
    },
    {
        id: 4,
        soal: 'Apakah anda merokok lebih banyak pada pagi hari?',
        pilihan: [
            {
                label: 'Ya',
                nilai: 1,
            },
            {
                label: 'Tidak',
                nilai: 0,
            }
        ]
    },
    {
        id: 5,
        soal: 'Apakah anda merasa kesulitan untuk tidak merokok ditempat/ area yang dilarang untuk merokok seperti bangunan umum, pesawat terbang atau di tempat kerja?',
        pilihan: [
            {
                label: 'Ya',
                nilai: 1,
            },
            {
                label: 'Tidak',
                nilai: 0,
            }
        ]
    },
    {
        id: 6,
        soal: 'Apakah anda tetap merokok walaupun anda sakit berat sehingga harus berbaring dalam sebagian besar waktu anda?',
        pilihan: [
            {
                label: 'Ya',
                nilai: 1,
            },
            {
                label: 'Tidak',
                nilai: 0,
            }
        ]
    },
]