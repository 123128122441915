import React from 'react'
import styled, { css } from 'styled-components/macro'
import Button from './Button'

const Intro = styled.div`
    margin-top: 8em;
    text-align: center;
`;

const btnCss = css`
    margin-top: 2em;
`;

export default function Mulai({props}) {

    const mulaiKuis = () => props(true)

    return (
        <Intro>
            <h1>RSUP Persahabatan</h1>
            <h4>Kuisioner Fagestorm</h4>
            <Button onClick={() => mulaiKuis()} css={btnCss}>Mulai</Button>
        </Intro>
    )
}
